import type { StatusSystemType } from "@/types";

const STATUS_SYSTEM_TYPES = new Map<StatusSystemType, StatusSystemType>([
	["bronze", "silver"],
	["silver", "gold"],
	["gold", "platinum"],
	["platinum", "sapphire"],
	["sapphire", "diamond"],
	["diamond", "vip"],
	["vip", "vip"]
]);
const MAX_LEVEL_STATUS_SYSTEM = 70;

const useStatusSystem = () => {
	const { data: appInitData } = useAppInitData();
	const { popupState } = usePopupsState();
	const { open } = useFunrizeModals();

	const welcomeStatusSystemCookie = useCookie("showWelcomeStatusSystem", {
		path: "/"
	});

	const rankLeague = computed(() => appInitData.value?.rankLeague);
	const pointsReceived = computed(() => rankLeague.value?.progressData?.level?.pointsReceived);
	const pointsNeedForNextLevel = computed(() => {
		if (
			typeof rankLeague.value?.progressData?.level?.pointsNeedForNextLevel === "number" &&
			typeof pointsReceived.value === "number"
		) {
			return rankLeague.value?.progressData?.level?.pointsNeedForNextLevel + pointsReceived.value;
		}
		return 0;
	});
	const level = computed(() => rankLeague.value?.progressData?.section?.level || 1);
	const nextLevel = computed(() => {
		if (!rankLeague.value) {
			return 2;
		}

		return level.value === MAX_LEVEL_STATUS_SYSTEM ? MAX_LEVEL_STATUS_SYSTEM : level.value + 1;
	});
	const levelMax = computed(() => rankLeague.value?.progressData?.section?.levelMax);
	const type = computed(() => rankLeague.value?.progressData?.section?.type || "bronze");
	const isConfirmedProfile = computed(
		() => appInitData.value?.emailConfirmed && appInitData.value?.profileCompleted && appInitData.value?.phoneConfirmed
	);
	const enabled = computed(() => rankLeague.value && !(!rankLeague.value?.enabled && isConfirmedProfile.value));
	const isStatusOnEntries = computed(() => ["platinum", "sapphire", "diamond", "vip"].includes(type.value));
	const progress = computed(() => {
		if (pointsReceived.value && pointsNeedForNextLevel.value) {
			return `${(100 / pointsNeedForNextLevel.value) * pointsReceived.value}%`;
		}

		return "0%";
	});

	const nextType = STATUS_SYSTEM_TYPES.get(type.value) || "silver";

	const showWelcomeStatusSystemPopup = () => {
		if (!rankLeague.value || popupState.value.isResetPasswordFlow) {
			return;
		}

		if (!popupState.value.isLoginFlow) {
			setTimeout(() => open("LazyOModalWelcomeStatusSystem", { isFirstLogin: true }), 2500);
		} else if (!welcomeStatusSystemCookie.value) {
			setTimeout(() => open("LazyOModalWelcomeStatusSystem", { isFirstLogin: false }), 2500);
		}

		welcomeStatusSystemCookie.value = "true";
	};

	return {
		pointsNeedForNextLevel,
		pointsReceived,
		level,
		nextLevel,
		levelMax,
		type,
		nextType,
		progress,
		isConfirmedProfile,
		isStatusOnEntries,
		rankLeague,
		enabled,
		appInitData,
		showWelcomeStatusSystemPopup
	};
};

export default useStatusSystem;
